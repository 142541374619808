import request from "@/utils/request";

// 韩语APP音变规则类型
export function SoundLawsType(params) {
    return request({
        url: '/admin/korean_v3/soundLawsType',
        method: 'get',
        params
    })
}

// 韩语APP音变规则列表
export function SoundLawsList(params) {
    return request({
        url: '/admin/korean_v3/soundLawsList',
        method: 'get',
        params
    })
}

// 音变规则上传COS签名
export function getSoundLawsCosToken(params) {
    return request({
        url: '/admin/korean_v3/getSoundLawsCosToken',
        method: 'get',
        params
    })
}

// 添加韩语APP音变规则
export function SoundLawsAdd(data) {
    return request({
        url: '/admin/korean_v3/soundLawsAdd',
        method: 'post',
        data
    })
}

// 编辑韩语APP音变规则
export function SoundLawsEdit(data) {
    return request({
        url: '/admin/korean_v3/soundLawsEdit',
        method: 'put',
        data
    })
}

// 删除韩语韩语APP音变规则
export function SoundLawsDel(data) {
    return request({
        url: '/admin/korean_v3/soundLawsDel',
        method: 'delete',
        data
    })
}