<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="分类">
          <el-select
            v-model="queryForm.rule_id"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in soundLawsTypeData"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryForm.title"
          ></el-input>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加音变规则</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="soundLawsList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
    
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="罗马音" prop="rome" align="center"></el-table-column>
       
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加音变规则 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="分类" label-width="100px">
            <el-select
                v-model="addForm.rule_id"
                placeholder="请选择分类"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in soundLawsTypeData"
                  :key="item.key"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.title"
              placeholder="请输入标题"
            />
        </el-form-item>
         <!-- <el-form-item el-form-item label="罗马音" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.rome"
              placeholder="请输入罗马音"
            />
        </el-form-item> -->

        <!-- <el-form-item el-form-item label="音频" label-width="100px">
          <div v-if="addForm.audio_url">
                <audio
                  :src="addForm.audio_url"
                  style="cursor: pointer;outline: none;"
                  controls="controls" 
                  ref="audioPlay"
                ></audio>
          </div>
          <el-button type="primary" @click="selectAudio"
            >{{isAdd=='add'?'上传音频':'修改音频'}}</el-button
          ><span class="file-name">{{ fileAudioName }}</span>
          <el-progress :percentage="audioProgress" v-if="audioProgress"></el-progress>
          <input
            type="file"
            name="audio"
            id=""
            hidden
            ref="audio"
            @change="selectAudioFiles"
            accept="audio/*"
          />
        </el-form-item> -->
        <el-form-item el-form-item label="视频" label-width="100px">
          <div v-if="addForm.video_url" class="video_box">
            <video
              :src="addForm.video_url"
              style="cursor: pointer;outline: none;"
              controls="controls" 
              ref="videoPlay"
            ></video>
          </div>
          <el-button type="primary" @click="selectvideo"
            >{{isAdd=='add'?'上传视频':'修改视频'}}</el-button
          ><span class="file-name">{{ fileName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            hidden
            ref="video"
            @change="selectVideoFiles"
            accept="video/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="提示" label-width="100px">
          <quill-editor
              v-model="addForm.tips"
              ref="myQuillEditor"
            >
          </quill-editor>
        </el-form-item>

       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  SoundLawsType,
  SoundLawsList,
  getSoundLawsCosToken,
  SoundLawsAdd,
  SoundLawsEdit,
  SoundLawsDel,
} from "@/api/Korean/sound_rule.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        rule_id: "",
        title: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      soundLawsList: [],
      soundLawsTypeData: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        rule_id: "",
        title: "",
        rome: "",
        audio: "",
        video: "",
        tips: "",
      },
      addtitle: "",
      isAdd: "add",
      fileName: "",
      progress: 0,
      audioProgress: 0,
      fileAudioName: "",
    };
  },

  created() {
    this.getSoundLawsType();
    this.getSoundLawsList();
  },
  methods: {
    //   日语APP音变规则类型
    getSoundLawsType() {
      SoundLawsType().then((res) => {
        this.soundLawsTypeData = res.data;
      });
    },
    //   日语APP音变规则列表
    getSoundLawsList() {
      SoundLawsList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.soundLawsList = res.data.data;
      });
    },

    // 删除音变规则
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该音变规则?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SoundLawsDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除音变规则成功");
        this.getSoundLawsList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加音变规则";
      this.lineData = [];
      this.addDialog = true;
      this.isCascader = true;
    },
    // 点击选择音频文件按钮
    selectAudio() {
      this.$refs.audio.click();
    },

    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSoundLawsCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.audioProgress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.audio = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },
    // 点击选择视频文件按钮
    selectvideo() {
      this.$refs.video.click();
    },

    // 选中视频
    selectVideoFiles(e) {
      let file = this.$refs.video.files[0];
      this.fileName = file.name;
      const isSize = file.size / 1024 / 1024 < 500;
      if (!isSize) {
        this.$message.error("上传视频不能超过 500MB!");
        return;
      }

      let that = this;
      getSoundLawsCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.video = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 添加音变规则
    addNow() {
      if (this.addForm.rule_id == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      }
      //  else if (this.addForm.rome == "") {
      //   this.$message.error("请输入罗马音");
      //   return;
      // }
      //  else if (this.addForm.audio == "") {
      //   this.$message.error("请上传音频");
      //   return;
      // } 
      else if (this.addForm.video == "") {
        this.$message.error("请上传视频");
        return;
      } else if (this.addForm.tips == "") {
        this.$message.error("请填写提示");
        return;
      }
      SoundLawsAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加音变规则成功");
        this.addDialog = false;
        this.getSoundLawsList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑音变规则";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.rule_id == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } 
      // else if (this.addForm.rome == "") {
      //   this.$message.error("请输入罗马音");
      //   return;
      // } else if (this.addForm.audio == "") {
      //   this.$message.error("请上传音频");
      //   return;
      // } 
      else if (this.addForm.video == "") {
        this.$message.error("请上传视频");
        return;
      } else if (this.addForm.tips == "") {
        this.$message.error("请填写提示");
        return;
      }
      SoundLawsEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改音变规则成功");
        this.addDialog = false;
        this.getSoundLawsList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        rule_id: "",
        title: "",
        rome: "",
        audio: "",
        video: "",
        tips: "",
      };
      this.fileName = "";
      this.fileAudioName = "";
      this.progress = 0;
      this.audioProgress = 0;

      if (this.$refs.videoPlay !== undefined) {
        this.$refs.videoPlay.pause();
      }
      if (this.$refs.audioPlay !== undefined) {
        this.$refs.audioPlay.pause();
      }
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getSoundLawsList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getSoundLawsList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getSoundLawsList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;

  img {
    width: 200px;
    height: 120px;
  }
}

.video_box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
